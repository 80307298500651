import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { SiteLayout, Head, Card } from '~/components';
import * as cases from '~/pages/cases/cases.module.scss';

const CasesPage = () => (
  <SiteLayout>
    <Head title="開発事例" />

    <StaticImage
      alt=""
      src="./images/index/hero.jpg"
      className={cases.heroImg}
      quality={100}
      layout="fullWidth"
    />

    <div className={cases.hero}>
      <h1 className={cases.heroTitle}>開発事例/製品紹介</h1>
    </div>

    <main className={cases.main}>
      <div className={cases.message}>
        <p>会津ラボの開発部はソリューション部門とクリエーション部門に分かれており、それぞれ受託開発と自社開発を行なっています。これまでさまざまな企業・自治体・大学などからソフトウェアの設計・開発・運用の依頼を受けてまいりました。その開発事例とエネルギー、医療等のいくつかの自社サービスについてもご紹介いたします。<br />
          詳細情報や、ご依頼・お見積もりについてはお気軽にお問い合わせください。</p>
      </div>
      <div className={cases.cards}>
        {/* <Card
          href="/cases/ems/"
          tag="自社開発"
          description="クランプ据置式スマートメーターによる<br />「電力見える化」システム"
        >
          <StaticImage
            alt="医療用"
            src="./images/index/thumb_ems.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card> */}
        <Card
          // href="/cases"
          disabled={true}
          tag="自社開発/自治体受託開発"
          description="地図が苦手な方も安心、目的地まで指さし案内<br />「指さしナビ」iOS/Android"
        >
          <StaticImage
            alt="指さしナビ"
            src="./images/index/thumb_yubisashinavi.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="/cases/aizukko_plus/"
          tag="会津若松市受託事業"
          description="家庭と学校をつなぐ情報配信アプリケーション<br />「あいづっこ＋」iOS/Android"
        >
          <StaticImage
            alt="あいづっこ＋"
            src="./images/index/thumb_aizukko.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="/cases/go/"
          tag="電気機器メーカー受託事業"
          description="カーシェアリングスマートフォンアプリ<br />「GO!」iOS/Android"
        >
          <StaticImage
            alt="GO!"
            src="./images/index/thumb_sharecar.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="/cases/ems/"
          tag="自社開発"
          description="クランプ据置式スマートメーターによる<br />「電力見える化」システム"
        >
          <StaticImage
            alt="電力見える化"
            src="./images/index/thumb_ems.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="/cases/smartplug/"
          tag="株式会社エナリス共同実証事業"
          description="ブロックチェーンを活用した<br />電力取引サービス"
        >
          <StaticImage
            alt="スマートプラグ"
            src="./images/index/thumb_smartplug.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="https://apps.apple.com/jp/app/%E3%82%AC%E3%83%B3%E3%83%9E%E8%A8%88%E7%AE%97/id1242211347"
          tag="自社開発（福島県立医科大学教授監修）"
          description="「ガンマ計‪算‬」<br />iOS"
        >
          <StaticImage
            alt="ガンマ計‪算‬"
            src="./images/index/thumb_gamma.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          href="https://apps.apple.com/jp/app/%E4%BA%8C%E6%AC%A1%E6%95%91%E5%91%BD%E5%87%A6%E7%BD%AE%E3%83%88%E3%83%AC%E3%83%BC%E3%83%8B%E3%83%B3%E3%82%B0%E3%82%A2%E3%83%97%E3%83%AA/id1021883612"
          tag="自社開発（福島県立医科大学教授監修）"
          description="「二次救命処置トレーニングアプ‪リ‬」<br />iOS"
        >
          <StaticImage
            alt="二次救命処置トレーニングアプ‪リ"
            src="./images/index/thumb_acls.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
        <Card
          // href="https://apps.apple.com/jp/app/cpr%E3%83%88%E3%83%AC%E3%83%BC%E3%83%8B%E3%83%B3%E3%82%B0-%E5%BF%83%E8%82%BA%E8%98%87%E7%94%9F%E3%81%AE%E9%81%94%E4%BA%BA/id883069496"
          tag="自社開発（福島県立医科大学教授監修）"
          description="「CPRトレーニング〜心肺蘇生の達人‪〜‬」<br />iOS"
          disabled={true}
        >
          <StaticImage
            alt="CPRトレーニング〜心肺蘇生の達人‪〜"
            src="./images/index/thumb_cpr.png"
            width={330}
            height={140}
            quality={100}
          />
        </Card>
      </div>
    </main>
  </SiteLayout>
);

export default CasesPage;